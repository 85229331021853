<template>
  <ERow>
    <ECol cols="12" class="py-0">
      <ReportTable
        name="projects"
        item-key="exid"
        :sort-desc="false"
        sort-by="status"
        :headers="headers"
        :provider="gateReportProjects"
        :copy-to-clipboard="false"
        :hide-show="false"
        :filter-fields="filterFields"
        :hide-default-footer="true"
        global-search
        @change="assignVerifiedDaysToProjects"
      >
        <template #actions-left>
          <div class="body-1 text--secondary my-3">Gate report</div>
        </template>
        <template #item.name="{ item }">
          <div class="project-item">
            <div class="d-inline">
              <NdaIcon v-if="isNDAProject(item)" />
              <nuxt-link :to="`/gate-report/${item.exid}`">
                <div class="d-inline-flex">
                  <ETruncatedDiv
                    class="cursor-pointer primary--text"
                    :width="isNDAProject(item) ? '8rem' : '9rem'"
                    fixed-width
                    >{{ item.name }}</ETruncatedDiv
                  >
                </div>
              </nuxt-link>
            </div>
            <v-btn
              icon
              color="primary"
              x-small
              @click="projectStore.openDialog(item.exid)"
            >
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon color="primary" v-bind="attrs" v-on="on">
                    fa-info-circle
                  </v-icon>
                </template>
                <span>View project details</span>
              </v-tooltip>
            </v-btn>
          </div>
        </template>
        <template #item.exid="{ item }">
          <span
            class="cursor-pointer primary--text"
            @click="projectStore.openDialog(item.exid)"
            >{{ item.exid }}
          </span>
        </template>
        <template #item.status="{ item }">
          <div :class="item.status">
            {{ item.status }}
          </div>
        </template>
        <template #item.cameras="{ item }">
          <CamerasColumn
            :item="item"
            :items-per-line="1"
            @open-camera-dialog="
              (camera) => cameraDialogStore.openDialog({ camera })
            "
          />
        </template>
        <template #item.cameraStatuses="{ item }">
          <span v-for="(camera, index) in item.cameras" :key="index">
            <span :class="getStatusColor(camera.status)">
              {{ getStatusText(camera.status) }}
            </span>
            <br />
          </span>
        </template>
        <template #item.lastDetection="{ item }">
          <div v-if="item.cameras" class="font-weight-medium">
            <div
              v-for="(camera, index) in item.cameras"
              :key="index"
              class="px-1 cursor-default"
            >
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    :class="getLastDetectedEventInfo(camera).class"
                    v-on="on"
                    >{{
                      camera.last_detected_event
                        ? camera.last_detected_event
                        : "-"
                    }}</span
                  >
                </template>
                <span>{{ getLastDetectedEventInfo(camera).text }}</span>
              </v-tooltip>
            </div>
          </div>
          <div v-else>n/a</div>
        </template>
        <template #item.relatedAnpr="{ item }">
          <div v-for="anprCamera in item.cameras" :key="anprCamera.exid">
            <CamerasColumn
              :item="anprCamera"
              :items-per-line="1"
              @open-camera-dialog="
                (camera) => cameraDialogStore.openDialog({ camera })
              "
            />
          </div>
        </template>

        <!-- MOTION DETECTION -->
        <template #item.motionDetection="{ item }">
          <div class="d-flex justify-center my-1">
            <v-icon
              v-if="isMotionDetectionProject(item)"
              class="icon green--text"
              >fa-square-check</v-icon
            >
            <span v-else>-</span>
          </div>
        </template>

        <!-- ExNVR -->
        <template #item.exNvr="{ item }">
          <div class="d-flex justify-center my-1">
            <v-icon v-if="hasExNvr(item)" class="icon green--text"
              >fa-square-check</v-icon
            >
            <span v-else>-</span>
          </div>
        </template>

        <!-- ExNVR -->
        <template #item.noLicensePlate="{ item }">
          <div class="d-flex justify-center my-1">
            <v-icon v-if="hasNoLicensePlate(item)" class="icon green--text"
              >fa-square-check</v-icon
            >
            <span v-else>-</span>
          </div>
        </template>
      </ReportTable>
    </ECol>
  </ERow>
</template>

<script>
import filterFields from "@/components/gateReport/gateReportProjectsSearchFilters"
import ReportTable from "@/components/ReportTable"
import headers from "@/components/gateReport/gateReportProjectsHeaders"
import CamerasColumn from "@/components/CamerasColumn"
import NdaIcon from "@/components/NdaIcon"
import { ProjectFeatureFlag } from "@evercam/shared/types/project"
import CameraUtils from "@/mixins/cameraUtils"
import { AiApi } from "@evercam/shared/api/aiApi"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useProjectStore } from "@/stores/projects"
import { NvrModel } from "@evercam/shared/types"
import { useReportStore } from "@/stores/report"

export default {
  name: "GateReport",
  components: {
    ReportTable,
    CamerasColumn,
    NdaIcon,
  },
  mixins: [CameraUtils],
  data() {
    return {
      filterFields: {},
      headers: headers,
      noteType: "gate_report",
      selectedCamera: null,
      note: "",
      isNoteLoading: false,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore, useProjectStore, useReportStore),
  },
  created() {
    this.filterFields = filterFields(this)
  },
  methods: {
    async gateReportProjects(requestParams = { params: {} }) {
      try {
        const items = await AiApi.gateReport.getGateReportProjects({
          ...requestParams,
          params: {
            ...requestParams.params,
            limit: 1000,
          },
        })

        return { items }
      } catch (error) {
        console.error("Error fetching gate report projects:", error)
      }
    },
    getLastDetectedEventInfo(camera) {
      if (!camera.last_detected_event) {
        return {
          class: "",
          text: "",
        }
      }

      const lastVerifiedDay = this.$moment(camera.last_detected_event)
      const currentDate = this.$moment() // Get the current date

      const distance = currentDate.diff(lastVerifiedDay, "days") // Calculate the difference between current date and last verified day

      return {
        text: `Last detected event is ${
          distance >= 2 ? distance + " days behind schedule." : "on schedule."
        }`,
        class: this.getDistanceClass(distance),
      }
    },

    getDistanceClass(distance) {
      if (distance >= 2) {
        if (distance >= 5) {
          return "red--text text--lighten-1"
        }

        return "orange--text text--lighten-1"
      }

      return "green--text text--lighten-1"
    },
    isNDAProject(item) {
      return item?.featureFlags?.includes(ProjectFeatureFlag.NdaMc)
    },
    isMotionDetectionProject(project) {
      return project?.featureFlags?.includes(
        ProjectFeatureFlag.GateReportMotionDetection
      )
    },
    hasExNvr(project) {
      return project?.cameras?.some(
        (c) => c.nvrConfig?.model === NvrModel.ExNvr
      )
    },
    hasNoLicensePlate(project) {
      return project?.featureFlags?.includes(ProjectFeatureFlag.NoLicensePlate)
    },
    async assignVerifiedDaysToProjects(projects) {
      try {
        this.reportStore.loading = true
        // Fetch verified days for all cameras
        const verifiedDays = await AiApi.gateReport.getLastVerified()
        // Iterate through each project and assign verified days for each camera
        projects.forEach((project) => {
          if (project.cameras) {
            project.cameras.forEach((camera) => {
              const verifiedDay = verifiedDays.find(
                (day) => day.cameraExid === camera.exid
              )
              if (verifiedDay) {
                // Add the last_detected_event property to the camera
                camera["last_detected_event"] = this.$moment(
                  verifiedDay.lastVerifiedDay
                ).format("YYYY-MM-DD")
              }
            })
          }
        })

        // Return the projects with updated verified days
        this.reportStore.items = projects
      } catch (error) {
        console.error("Error assigning verified days to projects:", error)
        throw error // Re-throw the error for handling elsewhere if needed
      } finally {
        this.reportStore.loading = false
      }
    },
  },
}
</script>

<style scoped>
.icon {
  font-size: 20px !important;
}
.project-item {
  display: grid;
  grid-template-columns: 6fr 1fr;
  min-width: 0;
}
</style>
